/* @import url('https://fonts.googleapis.com/css?family=Pacifico'); */
/* @import url('https://fonts.googleapis.com/css?family=Pacifico|Roboto'); */
@import url('https://fonts.googleapis.com/css?family=Alex+Brush|Kalam|Nunito|Nunito+Sans:200,400,600,700,800,900|Parisienne|Roboto|Roboto+Slab');

body {
  margin: 0;
  padding: 0;
  font-family: 'Nunito Sans', sans-serif;
}

.firebaseui-card-header {
    display: none;
}

.firebaseui-id-submit {
    color: #2A6Bff !important;
    background: transparent !important;
    box-shadow: none !important;
    font-weight: 400 !important;
}

.firebaseui-id-submit:hover {
    background: #f6f6f8 !important;
}

.firebaseui-container {
    box-shadow: none !important;
}

.firebaseui-textfield.mdl-textfield .firebaseui-label::after {
    background-color: #2A6Bff !important;
}

.TabIndicator-colorPrimary-0296 {
    background-color: transparent !important;
}
