@import url(https://fonts.googleapis.com/css?family=Alex+Brush|Kalam|Nunito|Nunito+Sans:200,400,600,700,800,900|Parisienne|Roboto|Roboto+Slab);
/* @import url('https://fonts.googleapis.com/css?family=Pacifico'); */
/* @import url('https://fonts.googleapis.com/css?family=Pacifico|Roboto'); */

body {
  margin: 0;
  padding: 0;
  font-family: 'Nunito Sans', sans-serif;
}

.firebaseui-card-header {
    display: none;
}

.firebaseui-id-submit {
    color: #2A6Bff !important;
    background: transparent !important;
    box-shadow: none !important;
    font-weight: 400 !important;
}

.firebaseui-id-submit:hover {
    background: #f6f6f8 !important;
}

.firebaseui-container {
    box-shadow: none !important;
}

.firebaseui-textfield.mdl-textfield .firebaseui-label::after {
    background-color: #2A6Bff !important;
}

.TabIndicator-colorPrimary-0296 {
    background-color: transparent !important;
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

